import styled from "styled-components";

export const HomeContainer = styled.div`
min-height:450px;
.chart-one-container{
    height:400px;
    width:400px;
}
.date-picker-container{
    text-align: right;
    padding-bottom: 40px;
}
.chart-container{
    padding-top:40px;
}
.date-title{
    font-size:10px;
}
.filter-labels{
    font-size:15px;
    font-weight:500;
    margin-right: 5px;
}
`;