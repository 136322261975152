import React, { useState, useEffect } from "react";
import { HomeContainer } from "./elements";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from "moment/moment";
import todayJson from '../../../assets/json/today.json'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Home = () => {

    const [predictions, setPredictions] = useState(todayJson);



    const Title = (station, selectedDate) => {
        console.log(selectedDate);
        if (selectedDate != null) {
            return station + ' (' + moment(selectedDate).format('MMMM Do YYYY')+')';
        }
        else {
            return station + ' (' + moment().format('MMMM Do YYYY')+')';
        }

    }

    const toTimestamp = (strDate) => {
        const dt = new Date(strDate).getTime();
        let temp = dt / 1000;
        moment.locale('en');
        return moment(strDate).format('HH');
    };

    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };

    const color = { st1: "#eb4034", st2: "#5e60e0", st3: "#3ebd60", st4: "#dbe846", st5: "#46e8e5" }

    const el = { st1: "Station 1", st2: "Station 2", st3: "Station 3", st4: "Station 4", st5: "Station 5" }
    const optionsSetup = {}
    Object.keys(el).map(key => {
        let o = {};
        o = { "station_key": el[key], "station_data": {} }
        optionsSetup[key] = o;
    })
    const [options, setOptions] = useState(optionsSetup);

    let dateOptions = {}

    const tifOptions = Object.keys(el).map(key => {
        dateOptions[key] = new Date()

    });
    const [datesFilter, setDatesFilter] = useState(dateOptions);

    useEffect(() => {
        let tempOptions = {}


        const tifOptions = Object.keys(el).map(key => {
            let hours = [];
            let dataPoints = [];
            let actulaDataPoints = [];


            predictions.map((prediction) => {
                hours.push(toTimestamp(prediction['0']));
                dataPoints.push(parseFloat(prediction['1']) + randomNumberInRange(1, 20));
                actulaDataPoints.push(parseFloat(prediction['1']) + randomNumberInRange(1, 20));

            })



            let optionsData = {
                chart: {
                    zoomType: 'xy'
                },
                title: {
                    text: Title(el[key]),
                    align: 'center'
                },
                subtitle: {
                    text: '',
                    align: 'Data Predictions'
                },
                xAxis: [{
                    categories: hours,
                    crosshair: true
                }],
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    title: {
                        text: 'Data Prediction',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: '',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    align: 'left',
                    x: 80,
                    verticalAlign: 'top',
                    y: 60,
                    floating: true,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || // theme
                        'rgba(255,255,255,0.25)'
                },
                series: [{
                    name: 'Predictions',
                    type: 'column',
                    yAxis: 1,
                    data: dataPoints,
                    tooltip: {
                        valueSuffix: ''
                    },
                    color: color[key]

                }, {
                    name: 'Actual Data',
                    type: 'scatter',
                    data: actulaDataPoints,
                    tooltip: {
                        valueSuffix: ''
                    }
                }]
            };
            let o = {};
            o = { "station_key": el[key], "station_data": optionsData }
            tempOptions[key] = o

            setOptions(prev => ({
                ...prev,
                ...tempOptions
            }));

        })

    }, [predictions]);


    const filterStation = (selectedStation, selectedDate) => {

        let tempOptions = {}

        let hours = [];
        let dataPoints = [];
        let actulaDataPoints = [];

        predictions.map((prediction) => {
            hours.push(toTimestamp(prediction['0']));
            dataPoints.push(parseFloat(prediction['1']) + randomNumberInRange(1, 20));
            actulaDataPoints.push(parseFloat(prediction['1']) + randomNumberInRange(1, 20));

        })

        let optionsData = {
            chart: {
                zoomType: 'xy'
            },
            title: {
                text: Title(el[selectedStation], selectedDate),
                align: 'center'
            },
            subtitle: {
                text: '',
                align: 'Data Predictions'
            },
            xAxis: [{
                categories: hours,
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: 'Data Prediction',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
            }, { // Secondary yAxis
                title: {
                    text: '',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                labels: {
                    format: '{value}',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                opposite: true
            }],
            tooltip: {
                shared: true
            },
            legend: {
                align: 'left',
                x: 80,
                verticalAlign: 'top',
                y: 60,
                floating: true,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || // theme
                    'rgba(255,255,255,0.25)'
            },
            series: [{
                name: 'Predictions',
                type: 'column',
                yAxis: 1,
                data: dataPoints,
                tooltip: {
                    valueSuffix: ''
                },
                color: color[selectedStation]

            }, {
                name: 'Actual Data',
                type: 'scatter',
                data: actulaDataPoints,
                tooltip: {
                    valueSuffix: ''
                }
            }]
        };
        let o = {};
        o = { "station_key": el[selectedStation], "station_data": optionsData }
        tempOptions[selectedStation] = o

        setOptions(prev => ({
            ...prev,
            ...tempOptions
        }));

    }

    const DateFilterContainer = ({ dateSelected, optionKey }) => {


        return (
            <DatePicker className={`form-control`} selected={dateSelected} onChange={(date) => {

                let limit = moment().add(1, 'days');


                if (date <= limit) {
                    let o = {};
                    o[optionKey] = date;

                    setDatesFilter(prev => ({
                        ...prev,
                        ...o
                    }));
                    filterStation(optionKey, date);
                }


            }} />
        );
    };

    return (
        <HomeContainer>
            {Object.keys(el).map(key => {

                return (
                    <div className="col-md-6 chart-container " key={`station-chart-${key}`} style={{ float: 'left' }}>

                        {Object.keys(options[key].station_data).length > 0 ?
                            <>
                                <div className="col-md-12 date-picker-container">
                                    <label className="filter-labels">Date</label>
                                    <DateFilterContainer dateSelected={datesFilter[key]} optionKey={key} />
                                </div>
                                <div className="col-md-12 ">
                                    <HighchartsReact highcharts={Highcharts} options={options[key].station_data} />
                                </div>
                            </>
                            : null}

                    </div>

                )
            })}
        </HomeContainer>
    )
}

export default Home;