import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMap from "highcharts/modules/map";
import { HeatMapDiv } from "./elements";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);


HighchartsMap(Highcharts);

const Heatmap = () => {

    const el = { st1: "1", st2: "2", st3: "3", st4: "4", st5: "5" }


    const randomNumberInRange = (min, max) => {
        return Math.floor(Math.random()
            * (max - min + 1)) + min;
    };

    const [options, setOptions] = useState(null);
    const [datesFilter, setDatesFilter] = useState(new Date());
    const [timeFilter, setTimeFilter] = useState('10:00');
    const [loading, setLoading] = useState(true);

    const Title = (selectedDate) => {

        if (selectedDate != null) {
            return 'Cycle Distribution At Stations <sub>(' + moment(selectedDate).format('MMMM Do YYYY') + ')</sub>';
        }
        else {
            return 'Cycle Distribution At Stations <sub>(' + moment().format('MMMM Do YYYY') + ')</sub>';
        }

    }

    useEffect(() => {
        if (loading == true) {
            let stationCountTemp = []
            Object.keys(el).map(key => {
                let o = {};
                let point = randomNumberInRange(1, 100);
                let p = []
                p.push({ "name": el[key], value: point })
                o = { "name": el[key], data: p }
                stationCountTemp.push(o);
            })

            const tempOptions = {
                chart: {
                    type: "packedbubble",
                    height: "70%"
                },
                title: {
                    text: Title(),
                    align: 'center'
                },
                tooltip: {
                    useHTML: true,
                    pointFormat: '<b>Cycle count:</b> {point.value}'
                },
                plotOptions: {
                    packedbubble: {
                        minSize: '30%',
                        maxSize: '120%',
                        zMin: 0,
                        zMax: 1000,
                        layoutAlgorithm: {
                            splitSeries: false,
                            gravitationalConstant: 0.02
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}',
                            style: {
                                color: 'black',
                                textOutline: 'none',
                                fontWeight: 'normal',
                                fontSize: 18
                            }
                        }
                    }
                },
                series: stationCountTemp
            };


            setOptions(tempOptions);
            setLoading(false);
        }
    }, [loading]);


    const filterStation = (selectedDate) => {

        let stationCountTemp = []
            Object.keys(el).map(key => {
                let o = {};
                let point = randomNumberInRange(1, 100);
                let p = []
                p.push({ "name": el[key], value: point })
                o = { "name": el[key], data: p }
                stationCountTemp.push(o);
            })

            const tempOptions = {
                chart: {
                    type: "packedbubble",
                    height: "70%"
                },
                title: {
                    text: Title(selectedDate),
                    align: 'center'
                },
                tooltip: {
                    useHTML: true,
                    pointFormat: '<b>Cycle count:</b> {point.value}'
                },
                plotOptions: {
                    packedbubble: {
                        minSize: '30%',
                        maxSize: '120%',
                        zMin: 0,
                        zMax: 1000,
                        layoutAlgorithm: {
                            splitSeries: false,
                            gravitationalConstant: 0.02
                        },
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}',
                            style: {
                                color: 'black',
                                textOutline: 'none',
                                fontWeight: 'normal',
                                fontSize: 18
                            }
                        }
                    }
                },
                series: stationCountTemp
            };


            setOptions(tempOptions);
            setLoading(false);

    }




    const DateFilterContainer = ({ dateSelected, optionKey }) => {


        return (
            <DatePicker className={`form-control`} selected={dateSelected} onChange={(date) => {
                let limit = moment().add(1, 'days');
                if (date <= limit) {
                    setDatesFilter(date);
                    //setLoading(true);
                    filterStation(date);
                }
            }} />
        );
    };

    return (
        <HeatMapDiv>
            <div className="row">
                <div className="col-md-12">
                    <div className="col-md-2"></div>
                    <div className="col-md-6 chart-container">
                        {options != null ? <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            constructorType={"mapChart"}
                        /> : null}
                    </div>
                    <div className="col-md-4">
                        <div>
                            <label className="filter-labels">Date</label>
                            <DateFilterContainer dateSelected={datesFilter} />
                        </div>
                        <div>
                            <label className="filter-labels">Time</label>
                            <TimePicker onChange={(time) => {

                                if (time != null) {
                                    setTimeFilter(time);
                                    //setLoading(true);
                                    filterStation(datesFilter);
                                }
                            }} value={timeFilter}
                                locale="sv-sv"
                                disableClock={true}
                                format={"HH:mm"}
                                className="timepicker-cst"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </HeatMapDiv>
    );

}




export default Heatmap;
