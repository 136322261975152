import React,{useState} from "react";
import { MenuContainer } from "./elements";
import { Link ,useLocation } from "react-router-dom";
import logo from './images/statistics.png'
import cross from '../../../assets/images/cross-svgrepo-com.svg'

const Menu = ({ show ,closeMenu}) => {
    const [menuShow, setMenuShow] = useState(show);
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    
    return (
        <MenuContainer>
            <nav>
                <a
                    href="/#"
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                    aria-label={"Close menu"}
                >
                    <img src={logo} style={{ width: '40px' }} />
                </a>
                <a
                    href="/#"
                    onClick={(e) => {
                        e.preventDefault();
                        closeMenu(false);
                    }}
                    aria-label={"Close menu"}
                >
                    <img src={cross} style={{width:'20px'}}/>
                </a>
            </nav>
            <div>
                <ul >
                    <li key="predictions-dashboard">
                        <Link to={'/'} className={splitLocation[1] === "" ? "active" : ""}>Prediction Dashboard</Link>
                    </li>
                    <li key="heat-map">
                        <Link to={'/heat-map'} className={splitLocation[1] === "heat-map" ? "active" : ""}>Bubble Chart</Link>
                    </li>
                    <li key="alert-column-map">
                        <Link to={'/alert-column-map'} className={splitLocation[1] === "alert-column-map" ? "active" : ""}>Column Chart</Link>
                    </li>
                </ul>
            </div>
        </MenuContainer>
    )
}

export default Menu