import React, { useState } from "react";
import { HeaderDiv, MenuWrapper,Wrapper } from "./elements";
import Menu from "../Menu";
import logo from './images/statistics.png'
import topLogo from '../../../assets/images/prediction.png'
import { Link,useLocation } from "react-router-dom";

const Header = () => {
    const [menuShow, setMenuShow] = useState(false);
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    
    const closeMenu = ({t}) =>{
        setMenuShow(t);
    }

    return (
        <HeaderDiv>
            <header class="header-fixed">

            <div class="header-limiter">

                <h1><Link to={'/'} className={splitLocation[1] === "" ? "selected" : ""}><img style={{width:'45px'}} src={topLogo} /></Link></h1>

                <nav>
                    <Link to={'/'} className={splitLocation[1] === "" ? "selected" : ""}>Home</Link>
                    <Link to={'/heat-map'} className={splitLocation[1] === "heat-map" ? "selected" : ""}>Bubble Chart</Link>
                    <Link to={'/alert-column-map'} className={splitLocation[1] === "alert-column-map" ? "selected" : ""}>Column Chart</Link>
                </nav>

            </div>

            </header>
            <MenuWrapper>
                <Wrapper>
                <a 
                    href="/#"
                    onClick={(e) => {
                        e.preventDefault();
                        setMenuShow(true);
                    }}
                >
                    <img alt="predictions-logo" src={logo} style={{ width: '40px' }}/>
                </a>
                </Wrapper>
                <nav className={menuShow ? "shown" : "hidden"}>
                    <Menu show={menuShow} closeMenu={closeMenu}/>
                </nav>
            </MenuWrapper>
        </HeaderDiv>
    )
}

export default Header;