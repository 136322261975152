import React from "react";
import { FooterDiv } from "./elements";

const Footer = () => {
    return (
        <FooterDiv>
            <footer className="blog-footer">
                <p>Reports generated by dev team @2023</p>
            </footer>
        </FooterDiv>
    )
}

export default Footer;