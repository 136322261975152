import styled from "styled-components";

export const HeaderDiv =styled.div`
.header-fixed{
  background-color:#292c2f;
  box-shadow:0 1px 1px #ccc;
  padding: 20px 40px;
  height: 80px;
  color: #ffffff;
  box-sizing: border-box;
  top:-100px;

  -webkit-transition:top 0.3s;
  transition:top 0.3s;
}
.header-fixed .header-limiter {
	max-width: 1200px;
	text-align: center;
	margin: 0 auto;
}
.header-fixed-placeholder{
	height: 80px;
	display: none;
}

/* Logo */

.header-fixed .header-limiter h1 {
	float: left;
	font: normal 28px Cookie, Arial, Helvetica, sans-serif;
	line-height: 40px;
	margin: 0;
}

.header-fixed .header-limiter h1 span {
	color: #5383d3;
}
.header-fixed .header-limiter a {
	color: #ffffff;
	text-decoration: none;
}

.header-fixed .header-limiter nav {
	font:16px Arial, Helvetica, sans-serif;
	line-height: 40px;
	float: right;
}

.header-fixed .header-limiter nav a{
	display: inline-block;
	padding: 0 5px;
	text-decoration:none;
	color: #ffffff;
	opacity: 0.9;
}

.header-fixed .header-limiter nav a:hover{
	opacity: 1;
}

.header-fixed .header-limiter nav a.selected {
	color: #608bd2;
	pointer-events: none;
	opacity: 1;
  text-decoration:underline;
}
body.fixed .header-fixed {
	padding: 10px 40px;
	height: 50px;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1;
}
body.fixed .header-fixed-placeholder {
	display: block;
}

body.fixed .header-fixed .header-limiter h1 {
	font-size: 24px;
	line-height: 30px;
}

body.fixed .header-fixed .header-limiter nav {
	line-height: 28px;
	font-size: 13px;
}
@media all and (max-width: 600px) {

	.header-fixed {
		padding: 20px 0;
		height: 75px;
	}

	.header-fixed .header-limiter h1 {
		float: none;
		margin: -8px 0 10px;
		text-align: center;
		font-size: 24px;
		line-height: 1;
	}

	.header-fixed .header-limiter nav {
		line-height: 1;
		float:none;
	}

	.header-fixed .header-limiter nav a {
		font-size: 13px;
	}
	.header-fixed .header-limiter nav a {
		text-decoration: underline !important;
	}

	body.fixed .header-fixed {
		display: none;
	}

}
`;

export const MenuWrapper = styled.div`
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
  min-height: 100%;
  text-align: right;
  padding-right: 50px;
  padding-top: 30px;
 
    & > nav {
      position: fixed;
      top: 0;
      right: 0;
      transition: 1s;
      z-index: 999999999999;
      box-shadow: 0 20px 20px rgba(0, 0, 0, 0.25);
      &.hidden {
        transform: translateX(100%);
      }
      &.shown {
        transform: translateX(0);
      }
  }
  
`;

export const Wrapper = styled.div`
  --width: 1440px;
  --outerMargin: 100px;
  --backgroundColor: ${(props) => props.backgroundColor || "var(--black)"};
  position: fixed;
    top: 15px;
    right: 15px;
  background-color: var(--backgroundColor);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  @media (max-width: 1439px) {
    --width: 1024px;
  }
  @media (max-width: 1023px) {
    --width: 768px;
  }
  @media (max-width: 767px) {
    --width: 375px;
    --width: 100vw;
  }
`;
