import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import HeatMap from './components/pages/HeatMap';
import AlertCoumns from './components/pages/AlertColumns';
import Header from './components/elements/Header';
import Footer from './components/elements/Footer/Footer';
import styled from 'styled-components';

const Body = styled.div`
padding:50px;
`;

function App() {
  return (
    <>
      <Header />
      <Body>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/heat-map" element={<HeatMap />} />
          <Route exact path="/alert-column-map" element={<AlertCoumns />} />
        </Routes>
      </Body>
      <Footer />
    </>
  );
}

export default App;
